import React, {useState} from 'react';
import {Alert} from "react-bootstrap";

const NotificationContext = React.createContext({
    message: '',
    showSuccess: (message, delay) => {},
    showError: (message) => {},
    hideMessage: () => {}
});

export const NotificationContextProvider = (props) => {
    const [message, setMessage] = useState('');
    const [messageVariant, setMessageVariant] = useState('');

    const showMessage = (message) => {
        window.scrollTo(0, 0);
        setMessage(message);

    }

    const showSuccess = (message, delay) => {
        setMessageVariant('success');
        showMessage(message);
        if (delay) {
            setTimeout(() => hideMessage(), delay);
        }
    }

    const showError = (message) => {
        setMessageVariant('danger');
        showMessage(message);
    }

    const hideMessage = () => {
        setMessage('');
    }

    return <NotificationContext.Provider value={{message: message, showSuccess: showSuccess, showError: showError, hideMessage: hideMessage}}>
        {message && <Alert variant={messageVariant} onClose={hideMessage} dismissible>
        {message}
        </Alert>}{props.children}</NotificationContext.Provider>
}

export  default NotificationContext;