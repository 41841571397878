import React, {useEffect, useState} from "react";
import {login as serverLogin} from "../Services/api";

const AuthContext = React.createContext({
    isLoggedIn: false,
    login: (username, password) => {},
    logout: () => {},
});

export const AuthContextProvider = (props) => {
    const tokenLocalStorageKey = 'LOGIN_TOKEN';
    const [isLoggedIn, setIsLoggedIn] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem(tokenLocalStorageKey);
        if (token) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, []);

    const login = async (username, password) => {
        const response = await serverLogin(username, password);
        if (response.token) {
            localStorage.setItem(tokenLocalStorageKey, response.token);
            setIsLoggedIn(true);
        }
    };

    const logout = () => {
        localStorage.removeItem(tokenLocalStorageKey);
        setIsLoggedIn(false);
    }

    return (
        <AuthContext.Provider value={{isLoggedIn: isLoggedIn, login: login, logout: logout}}>
            {props.children}</AuthContext.Provider>
    );
}

export default AuthContext;