import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import styles from './Header.module.css';

const Header = (props) => {
    return (
        <Navbar bg="secondary" variant="dark">
            <Navbar.Brand className={styles.longHeading}>Meeting Indicator</Navbar.Brand>
            <Navbar.Brand className={styles.shortHeading}>MI</Navbar.Brand>
            <Nav className="ml-auto" onSelect={props.onNavLinkClick}>
                {props.navLinks.map((link) => {
                    return <Nav.Link className="active" key={link.key} eventKey={link.key}>{link.name}</Nav.Link>;
                })}
            </Nav>
        </Navbar>
    );
}

export default Header;