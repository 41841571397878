export const serverHostUrl =  window.location.protocol + "//" + window.location.host;

const genericErrorMessage = 'Something Went Wrong..!';
export const getIndicators = async () => {
    let errorMessageFromServer = false;
    try {
        const response = await fetch(serverHostUrl + '/api/indicators');
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            errorMessageFromServer = !!data?.message;
            throw (data?.message ? data.message : genericErrorMessage);
        }
    } catch (error) {
        throw (errorMessageFromServer ? error : genericErrorMessage);
    }

};

export const changeIndicatorStatus = async (name, status) => {
    let errorMessageFromServer = false;
    try {
        const response = await fetch(
            serverHostUrl + '/api/indicators/' + name,
            {
                method: "PATCH",
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('LOGIN_TOKEN')
                },
                body: JSON.stringify({status: status})
            });
        const data = await response.json();
        if (response.ok) {
            return true;
        } else {
            errorMessageFromServer = !!data?.message;
            throw (data?.message ? data.message : genericErrorMessage);
        }
    } catch (error) {
        throw (errorMessageFromServer ? error : genericErrorMessage);
    }
}

export const addIndicator = async (indicator) => {
    let newIndicator = {name: indicator.indicatorName};
    if (indicator.activeStatusText) {
        newIndicator.activeText = indicator.activeStatusText;
    }
    if (indicator.inactiveStatusText) {
        newIndicator.inactiveText = indicator.inactiveStatusText;
    }
    let errorMessageFromServer = false;
    try {
        const response = await fetch(serverHostUrl + '/api/indicators/', {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('LOGIN_TOKEN')
            },
            body: JSON.stringify(newIndicator)
        });
        const data = await response.json();
        if (response.ok) {
            return true;
        } else {
            errorMessageFromServer = !!data?.message;
            throw (data?.message ? data.message : genericErrorMessage);
        }
    } catch (error) {
        throw (errorMessageFromServer ? error : genericErrorMessage);
    }
}

export const deleteIndicator = async (name) => {
    debugger;
    let errorMessageFromServer = false;
    try {
        const response = await fetch(serverHostUrl + '/api/indicators/'+name, {
            method: "DELETE",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('LOGIN_TOKEN')
            },
            body: JSON.stringify({name: name})
        });
        if (response.ok) {
            return true;
        } else {
            throw (genericErrorMessage);
        }
    } catch (error) {
        throw (errorMessageFromServer ? error : genericErrorMessage);
    }
}

export const login = async (username, password) => {
    let errorMessageFromServer = false;
    try {
        const response = await fetch(serverHostUrl + '/api/login/', {
            method: "POST",
            headers: {"Content-type": "application/json"},
            body: JSON.stringify({username: username, password: password})
        });
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            errorMessageFromServer = !!data?.message;
            throw (data?.message ? data.message : genericErrorMessage);
        }
    } catch (error) {
        throw (errorMessageFromServer ? error : genericErrorMessage);
    }
}