import {Button, Spinner, Table} from "react-bootstrap";
import styles from './Indicators.module.css';
import IndicatorStatus from "./IndicatorStatus";
import {serverHostUrl, getIndicators} from "../../Services/api";
import {useCallback, useContext, useEffect, useState, Fragment} from "react";
import NotificationContext from "../../store/notification-context";
import { BsFillTrashFill } from "react-icons/bs";

const Indicators = (props) => {
    const [indicatorList, setIndicatorList] = useState([]);
    const [loading, setLoading] = useState(true);
    const notificationContext = useContext(NotificationContext);
    const showError = useCallback(notificationContext.showError, []); // eslint-disable-line
    useEffect(() => {
        getIndicators().then((indicators) => {
            setIndicatorList(indicators);
            console.log(indicators);
            setLoading(false);
        }).catch((message) => {
            showError(message);
            setLoading(false);
        });
    },[showError, props.newIndicatorCount])

    const deleteClickHandler = (name) => {
        props.onDelete(name);
    }

    const desktopView = <div className={styles.indicatorTableContainer + ' ' + styles.desktopView}>
        <Table striped bordered hover responsive>
            <thead>
            <tr>
                <th>Indicator</th>
                <th>Status</th>
                <th>URL</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {indicatorList.map((indicator, index) => {
                return (
                    <tr key={index} className={styles.indicatorTableRow}>
                        <td>{`Indicator ${index+1}: ${indicator.name}`}</td>
                        <td><IndicatorStatus name={indicator.name} status={indicator.status} activeText={indicator.activeText} inactiveText={indicator.inactiveText} /></td>
                        <td>{`${serverHostUrl}/${indicator.name}`}</td>
                        <td className={styles.deleteIcon} onClick={deleteClickHandler.bind(undefined, indicator.name)}><BsFillTrashFill/></td>
                    </tr>
                );
            })}
            </tbody>
        </Table>
    </div>;

    const mobileView = <div className={styles.mobileView + ' ' + styles.indicatorTableContainer}>
        {indicatorList.map((indicator, index) => {
            return (
                <Table key={index} striped bordered hover responsive>
                    <tbody>
                    <tr className={styles.indicatorTableRow}>
                        <td>{`Indicator ${index+1}: ${indicator.name}`}</td>
                    </tr>
                    <tr className={styles.indicatorTableRow}>
                        <td><IndicatorStatus name={indicator.name} status={indicator.status} activeText={indicator.activeText} inactiveText={indicator.inactiveText} /></td>
                    </tr>
                    <tr className={styles.indicatorTableRow}>
                        <td>{`${serverHostUrl}/${indicator.name}`}</td>
                    </tr>
                    <tr className={styles.indicatorTableRow}>
                        <td><Button onClick={deleteClickHandler.bind(undefined, indicator.name)} variant="danger">Delete</Button></td>
                    </tr>
                    </tbody>
                </Table>
            );
        })}
    </div>;

    return (
        <Fragment>
            {loading && <div className={styles.spinner}><Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner></div>}
            {!loading && desktopView}
            {!loading && mobileView}
        </Fragment>
    );
}

export default Indicators;