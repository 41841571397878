import {Fragment, useContext, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {deleteIndicator as removeIndicator} from "../../Services/api"
import NotificationContext from "../../store/notification-context";



const DeleteIndicator = (props) => {
    const notificationContext = useContext(NotificationContext);
    const [indicatorDeleting, setIndicatorDeleting] = useState(true);
    const [indicatorDeleted, setIndicatorDeleted] = useState(false);

    const deleteIndicatorHandler =  () => {
        removeIndicator(props.name).then(() => {
            notificationContext.showSuccess(`Indicator ${props.name} was deleted successfully`, 5000);
            setIndicatorDeleted(true);
            setIndicatorDeleting(false);
        }).catch((error) => {
            notificationContext.showError(error);
            setIndicatorDeleting(false);
        });
    }

    return (
        <Fragment>
            <Modal show={indicatorDeleting} onExited={() => props.onComplete(indicatorDeleted)} onHide={() => {setIndicatorDeleting(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You are about to delete the indicator with name <b>{props.name}</b>. Iframes embedding this indicator will not work anymore (unless you add a new indicator with the same name again). Are you sure?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIndicatorDeleting(false)}>
                        Close
                    </Button>
                    <Button type="submit" variant="danger" onClick={deleteIndicatorHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default DeleteIndicator;