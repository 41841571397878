import './App.css';
import {Fragment, useContext, useState} from "react";
import Header from "./Components/UI/Header";
import Indicators from "./Components/Screens/Indicators";
import {NotificationContextProvider} from "./store/notification-context";
import AddIndicator from "./Components/Screens/AddIndicator";
import Login from "./Components/Screens/Login";
import AuthContext from "./store/auth-context";
import DeleteIndicator from "./Components/Screens/DeleteIndicator";

function App() {
    const navLinks = [
        {key: 'addIndicator', name: 'Add Indicator'},
        {key: 'logout', name: 'Logout'}
    ];
    const [indicatorsToBeAdded, setIndicatorsToBeAdded] = useState(false);
    const [newIndicatorCount, setNewIndicatorCount] = useState(0);
    const [deletingIndicatorName, setDeletingIndicatorName] = useState('');
    const authContext = useContext(AuthContext);

    const navLinkClickHandler = (navLinkKey) => {
        if (navLinkKey === navLinks[0].key) {
            setIndicatorsToBeAdded(true);
        } else if (navLinkKey === navLinks[1].key) {
            authContext.logout();
        }
    }

    const indicatorDeleteHandler = (name) => {
        setDeletingIndicatorName(name);
    }

    const indicatorDeleteCompleteHandler = (deleted) => {
        setDeletingIndicatorName('');
        if (deleted) {
            setNewIndicatorCount((oldCount) => oldCount - 1);
        }
    }

    const indicatorsAddHandler = () => {
        setNewIndicatorCount((oldCount) => oldCount + 1);
    }

    return (
        <Fragment>
            <Header navLinks={authContext.isLoggedIn?navLinks:[]} onNavLinkClick={navLinkClickHandler}/>
            <NotificationContextProvider>
                {authContext.isLoggedIn === false && <Login/>}
                {authContext.isLoggedIn && indicatorsToBeAdded && <AddIndicator show={indicatorsToBeAdded} onAdd={indicatorsAddHandler} onClose={() => setIndicatorsToBeAdded(false)}/>}
                {authContext.isLoggedIn && deletingIndicatorName && <DeleteIndicator name={deletingIndicatorName} onComplete={indicatorDeleteCompleteHandler} />}
                {authContext.isLoggedIn && <Indicators newIndicatorCount={newIndicatorCount} onDelete={indicatorDeleteHandler}/>}
            </NotificationContextProvider>
        </Fragment>
    );
}

export default App;
