import {Button, Card, Form} from "react-bootstrap";
import styles from "./Login.module.css";
import {useContext, useState} from "react";
import AuthContext from "../../store/auth-context";
import NotificationContext from "../../store/notification-context";


const Login = () => {
    const authContext = useContext(AuthContext);
    const notificationContext = useContext(NotificationContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const loginHandler = (event) => {
        event.preventDefault();
        authContext.login(username, password).then(() => {
            notificationContext.hideMessage();
        }).catch((error) => {
            notificationContext.showError(error);
        });
    }

    return (
        <Card className={styles.loginCard}>
            <Form noValidate onSubmit={loginHandler}>
                <Form.Group controlId="username">
                    <Form.Label>User name</Form.Label>
                    <Form.Control required type="input" placeholder="Enter user name" onChange={(e) => setUsername(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                        Required
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control required type="password" placeholder="Enter password" onChange={(e) => setPassword(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                        Required
                    </Form.Control.Feedback>
                </Form.Group>
                <Button type="submit" variant="primary">
                    Login
                </Button>
            </Form>
        </Card>
    );
}

export default Login;