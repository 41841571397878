import {ButtonGroup, ToggleButton} from "react-bootstrap";
import {useContext, useState} from "react";
import {changeIndicatorStatus} from "../../Services/api";
import NotificationContext from "../../store/notification-context";

const IndicatorStatus = (props) => {
    const [radioValue, setRadioValue] = useState(props.status?'1':'0');
    const notificationContext = useContext(NotificationContext);

    const statusChangeHandler = (event) => {
        changeIndicatorStatus(props.name, (event.target.value === '1')?true:false).then(() => {
            setRadioValue(event.target.value)
            notificationContext.showSuccess('Successfully Updated', 5000)
        }).catch((error) => {
            notificationContext.showError(error);
        });
    }

    const radios = [
        { name: props.activeText, value: '1' },
        { name: props.inactiveText, value: '0' }
    ];
    return (
        <ButtonGroup toggle>
            {radios.map((radio, idx) => (
                <ToggleButton
                    key={idx}
                    type="radio"
                    variant="secondary"
                    name="radio"
                    value={radio.value}
                    checked={radio.value === radioValue}
                    onChange={statusChangeHandler}
                >
                    {radio.name}
                </ToggleButton>
            ))}
        </ButtonGroup>
    );
}

export default IndicatorStatus;