import {useState, Fragment, useEffect, useContext} from "react";
import {Button, Modal, Form} from "react-bootstrap";
import {addIndicator} from "../../Services/api";
import NotificationContext from "../../store/notification-context";
import {serverHostUrl} from "../../Services/api";

const AddIndicator = (props) => {
    const notificationContext = useContext(NotificationContext);
    const [show, setShow] = useState(false);
    const [indicatorName, setIndicatorName] = useState('');
    const [activeStatusText, setActiveStatusText] = useState('');
    const [inactiveStatusText, setInactiveStatusText] = useState('');
    const [validationMessage, setValidationMessage] = useState('Required');

    const inputPattern = '^[a-zA-Z0-9_]*$';
    const indicatorNameHandler = (event) => {
        if (event.currentTarget.value.trim() === '') {
            setValidationMessage('Required');
        } else if (!(new RegExp(inputPattern)).test(event.currentTarget.value)) {
            setValidationMessage('English letters, numbers and underscores only');
        }
        setIndicatorName(event.currentTarget.value);
    };
    const indicatorActiveStatusTextHandler = (event) => {
        setActiveStatusText(event.currentTarget.value);
    }
    const indicatorInactiveStatusTextHandler = (event) => {
        setInactiveStatusText(event.currentTarget.value);
    }

    useEffect(() => {
        setShow(props.show)
    },[props.show]);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            addIndicator({indicatorName, activeStatusText, inactiveStatusText}).then(() => {
                notificationContext.showSuccess(`Indicator added successfully. Use the url "${serverHostUrl}/${indicatorName}" in an iframe to use the indicator.`);
                props.onClose();
                props.onAdd();
            }).catch((error) => {
                notificationContext.showError(error);
                props.onClose();
            });
        }
    }

    return (
        <Fragment>
            <Modal show={show} onExited={props.onClose} onHide={() => {setShow(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Meeting Indicator</Modal.Title>
                </Modal.Header>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                        <Form.Group controlId="indicatorName">
                            <Form.Label>Meeting Indicator Name</Form.Label>
                            <Form.Control onChange={indicatorNameHandler} required maxlength="30" pattern={inputPattern} type="input" placeholder="Enter name" />
                            {validationMessage && <div className="invalid-feedback">
                                {validationMessage}
                            </div>}
                        </Form.Group>
                        <Form.Group controlId="activeStatusText">
                            <Form.Label>Active Status Text</Form.Label>
                            <Form.Control onChange={indicatorActiveStatusTextHandler} maxlength="50"  type="input" placeholder="Active" />
                        </Form.Group>
                        <Form.Group controlId="inactiveStatusText">
                            <Form.Label>Inactive Status Text</Form.Label>
                            <Form.Control onChange={indicatorInactiveStatusTextHandler} maxlength="50"  type="input" placeholder="Offline" />
                        </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary">
                        Add
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default AddIndicator;